import MainNav from './main-nav';

function initAll() {
  const $mainNav = document.querySelector('[data-module="app-main-nav"]');
  if ($mainNav) {
    new MainNav($mainNav).init();
  }
}

export default initAll;
