function MainNav($module) {
  if (!$module) {
    return this;
  }

  this.$module = $module;

  this.toggleButton = $module.querySelector('.app-main-nav__toggle-button');
  const mainNavListId = this.toggleButton.getAttribute('aria-controls');
  this.mainNavList = document.getElementById(mainNavListId);

  this.expandableTopLevelItems = $module.querySelectorAll(
    '.app-main-nav__top-level-item--expandable'
  );

  this.expandableSubmenuItems = $module.querySelectorAll(
    '.app-main-nav__submenu-item--expandable'
  );

  this.expandedTopLevelItem = null;
  this.expandedSubmenuItem = null;
}

MainNav.prototype.init = function () {
  if (!this.$module || !this.expandableTopLevelItems) {
    return;
  }

  this.toggleButton.setAttribute('aria-expanded', 'false');

  this.toggleButton.addEventListener(
    'click',
    this.handleToggleButtonClick.bind(this)
  );

  this.expandableTopLevelItems.forEach((topLevelItem) => {
    const submenu = topLevelItem.querySelector('.app-main-nav__submenu');
    const submenuId = `submenu-${topLevelItem.getAttribute('id')}`;

    submenu.setAttribute('id', submenuId);
    topLevelItem.setAttribute('aria-controls', submenuId);

    topLevelItem
      .querySelector('a')
      .addEventListener('click', (e) =>
        this.handleTopLevelItemClick.call(this, e, topLevelItem)
      );
  });

  this.expandableSubmenuItems.forEach((submenuItem) => {
    const subsubmenu = submenuItem.querySelector('.app-main-nav__subsubmenu');
    const subsubmenuId = `subsubmenu-${submenuItem.getAttribute('id')}`;

    subsubmenu.setAttribute('id', subsubmenuId);
    submenuItem.setAttribute('aria-controls', subsubmenuId);

    submenuItem
      .querySelector('a')
      .addEventListener('click', (e) =>
        this.handleSubmenuItemClick.call(this, e, submenuItem)
      );
  });

  this.syncState();
};

MainNav.prototype.syncState = function () {
  this.expandableTopLevelItems.forEach((topLevelItem) => {
    const isExpanded = topLevelItem === this.expandedTopLevelItem;
    if (isExpanded) {
      topLevelItem.setAttribute('aria-expanded', 'true');
    } else {
      topLevelItem.setAttribute('aria-expanded', 'false');
    }

    const submenuId = topLevelItem.getAttribute('aria-controls');
    const submenu = document.getElementById(submenuId);
    submenu.classList.toggle('app-main-nav__submenu--expanded', isExpanded);
    topLevelItem.classList.toggle(
      'app-main-nav__top-level-item--active',
      isExpanded
    );
  });

  this.expandableSubmenuItems.forEach((submenuItem) => {
    const isExpanded = submenuItem === this.expandedSubmenuItem;
    if (isExpanded) {
      submenuItem.setAttribute('aria-expanded', 'true');
    } else {
      submenuItem.setAttribute('aria-expanded', 'false');
    }

    const subsubmenuId = submenuItem.getAttribute('aria-controls');
    const subsubmenu = document.getElementById(subsubmenuId);
    subsubmenu.classList.toggle(
      'app-main-nav__subsubmenu--expanded',
      isExpanded
    );
    submenuItem.classList.toggle(
      'app-main-nav__submenu-item--active',
      isExpanded
    );
  });
};

MainNav.prototype.handleTopLevelItemClick = function (e, topLevelItem) {
  e.preventDefault();
  const isCurrentlyExpanded =
    topLevelItem.getAttribute('aria-expanded') === 'true';

  if (isCurrentlyExpanded) {
    this.expandedTopLevelItem = null;
  } else {
    this.expandedTopLevelItem = topLevelItem;
  }

  this.expandedSubmenuItem = null;
  this.syncState();
};

MainNav.prototype.handleSubmenuItemClick = function (e, submenuItem) {
  e.preventDefault();

  const isCurrentlyExpanded =
    submenuItem.getAttribute('aria-expanded') === 'true';

  if (isCurrentlyExpanded) {
    this.expandedSubmenuItem = null;
  } else {
    this.expandedSubmenuItem = submenuItem;
  }

  this.syncState();
};

MainNav.prototype.handleToggleButtonClick = function () {
  const isCurrentlyExpanded =
    this.toggleButton.getAttribute('aria-expanded') === 'true';

  if (isCurrentlyExpanded) {
    this.toggleButton.setAttribute('aria-expanded', 'false');
  } else {
    this.toggleButton.setAttribute('aria-expanded', 'true');
  }

  this.toggleButton.classList.toggle(
    'app-main-nav__toggle-button--active',
    !isCurrentlyExpanded
  );
  this.mainNavList.classList.toggle(
    'app-main-nav__top-level-list--expanded',
    !isCurrentlyExpanded
  );
};

export default MainNav;
